#myVideo {
  width: 60%; 
  /* z-index: -2;  */
  /* object-fit: cover; */
}

/* .video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: -1;
} */