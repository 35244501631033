.ml11 {
    font-weight: 700;
    font-size: 1.5em;
  }
  
  .ml11 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.1em;
    padding-right: 0.05em;
    padding-bottom: 0.15em;
  }
  
  .ml11 .line {
    opacity: 0;
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #fff;
    transform-origin: 0 50%;
  }
  
  .ml11 .line1 { 
    top: 0; 
    left: 0;
  }


  .hoverLink:hover {
    color: #f8bf2e; /* Color on hover */
  }

/* Emoji font fallback for Safari */
.ml11 .letters span {
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
}